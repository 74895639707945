import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
// eslint-disable-next-line
// import particlesJs from 'particles.js'
import PropTypes from 'prop-types'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'
import { ArrowRight } from 'react-feather'
import { Icon } from 'react-bulma-components'

import './styles/index.scss'

class IndexPage extends React.Component {
  startParticles() {
    const windowGlobal = typeof window !== 'undefined' && window
    windowGlobal.particlesJS('parts', {
      particles: {
        number: {
          value: 10,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: '#333'
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0,
            color: '#000000'
          },
          polygon: {
            nb_sides: 5
          },
          image: {
            src: 'img/github.svg',
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0.2,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#333',
          opacity: 0.2,
          width: 1
        },
        move: {
          enable: true,
          speed: 3,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: 'window',
        events: {
          onhover: {
            enable: true,
            mode: 'repulse'
          },
          onclick: {
            enable: true,
            mode: 'repulse'
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 200,
            line_linked: {
              opacity: 0.2
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    })
  }
  componentDidMount() {
    import('particles.js')
      .then(() => {
        this.startParticles()
      })
      // eslint-disable-next-line
      .catch(error => console.error(error))
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        className="index"
      >
        <SEO title="Andrey Teologov" />
        <h1 className="title name is-2 is-spaced">
          <span itemProp="name">Andrey Teologov</span>
        </h1>
        <p>
          <Img
            className="teologov-avatar"
            alt="Andrey Teologov"
            fixed={this.props.data.teologovAvatar.childImageSharp.fixed}
            Tag="span"
          />
        </p>
        <h2 className="subtitle is-4">
          Engineer, musician, individual, Ukrainian
        </h2>
        <p>
          I help companies to make high-quality tech products in different
          fields of business.
          <br className="is-hidden-small-screen" />
        </p>
        <br />
        <p style={{ marginBottom: 0 }}>
          Recently I started my journey as an indie maker.
        </p>
        <p>
          Check out my products
          <Link
            className="apps-link"
            to="/apps/"
            style={{ color: '#ff3860', borderColor: '#ff3860' }}
          >
            {' '}
            here
            <Icon style={{ top: 3, position: 'relative' }} className="arrow">
              <ArrowRight size={16} />
            </Icon>
          </Link>
        </p>
      </Layout>
    )
  }
}
IndexPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    teologovAvatar: file(absolutePath: { regex: "/teologov.jpeg/" }) {
      childImageSharp {
        fixed(width: 100, height: 100, quality: 90, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
